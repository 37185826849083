import axios from "../axios/index"

// 获取Banner列表
export function getBanner(result){
    axios.get('/blog/rest/banner/index_rolling_banner').then((res) => {
        result(res)
    })
}

// 获取首页新闻列表  /blog/rest/region/recommend
export function getRecommend(params,result){
    axios.get('/blog/rest/region/recommend',{
        params:{
            pageNum : params.pageNum,
            pageSize : params.pageSize
        }
    }).then((res) => {
        result(res)
    })
}


// 获取文章分类列表  id=1 资讯  2 新闻  3 政策
export function getCategory(id,params,result){
    axios.get('/blog/rest/category/'+id,{
        params:{
            pageNum : params.pageNum,
            pageSize : params.pageSize
        }
    }).then((res) => {
        result(res)
    })
}

// 获取文章详情
export function getArticle(id,result){
    axios.get('/blog/rest/article/'+id).then((res) => {
        result(res)
    })
}